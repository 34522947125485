export const FLAG = {
  CSMC_VEHICLE_HUB_SECTIONS: 'scon__global_progress_and_updated_vh_designs',
  SKIP_EXPIRED_ENQUIRY: 'tc_skip_expired_enquiry',
} as const;

export const EVENT = {
  ENQUIRY_CREATED: 'enquiry_created',
  OFFER_SELECT: 'offer_select',
  VEHICLE_HUB_EXPLAINING_MODAL_CLICK: 'vehicle_hub_explaining_modal_click',
  VEHICLE_HUB_EXPLAINING_MODAL_CLOSE: 'vehicle_hub_explaining_modal_close',
  VEHICLE_HUB_VIEW_SECTIONS: 'vehicle_hub_view_sections',
} as const;

export const EXPERIMENT_DECISIONS_HEADER = 'experiment-decisions';
